.modal{
  min-width: 50vw;
}

.productImage{
  height: 200px;
  width: auto;
}

.confirmationText{
  margin-top: 2rem;
}

.replacementTitle{
  font-size: 1.5rem;
}
