.button {
  color: var(--green);
  border-color: var(--green);
}

.layoutContent {
  width: 100%;
  padding-left: 60px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
}

.logo {
  margin: 20px;
  height: 65px;
}

.logoSmall {
  margin: 10px;
  height: 30px;
}

.sideBar,
.sideBarWithPointer {
  z-index: 2;
  box-shadow: 6.7px 0 5.3px rgba(0, 0, 0, 0.048);
  overflow: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  background-image: url('../../../img/menu-background.png');
  background-size: cover;
}

.sideBarWithPointer {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-y: hidden;
}

.menu {
  background-color: transparent;
}

.menuRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.menuIcon {
  font-size: 22px;
}

.closeIconContainer {
  border-radius: 100%;
  top: 50px;
  left: 187px;
  -webkit-box-shadow: 1px 1px 7px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 7px 5px rgba(0, 0, 0, 0.2);
  z-index: 10 !important;
  cursor: pointer;
  position: absolute;
  background-color: #fff;
}

.closeIcon {
  font-size: 14px;
  padding: 5px;
  color: #000;
  stroke-width: 30;
  stroke: #000;
}

.logoutContainer {
  padding: 20px 0;
  margin-left: 24px;
}
