.stepCircleFilled, .stepCircle{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid var(--gray);
}

.stepCircleFilled{
  animation: appearColor 1s normal;
  transition: appear 1s;
  background-color: var(--green);
}

.trace{
  width: 100px;
  height: 2px;
  background-color: var(--gray);
}

.rowContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}


@keyframes appearColor {
  from {
    background-color: #fff;
  }
}
