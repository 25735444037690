.input, .todayInput{
  display: none;
  position: relative;
}

.input + label, .todayInput + label{
  width: 60px;
  height: 60px;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.075);
  margin: 5px 10px 5px 0;
  border: 2px solid var(--green);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 500ms;
  position: relative;
}

.todayInput + label{
  padding-top: 10px;
}

.input + label:hover, .todayInput + label:hover{
  background-color: var(--lightGreen);
}

.input:checked + label, .todayInput:checked + label{
  background-color: var(--green);
  transform: scale(1.15);
  color: #fff;
}

.day, .weekDay{
  margin: 0
}

.day{
  color: #4C4C3F;
  font-weight: bold;
  font-size: large;
  line-height: 15px;
}

.weekDay{
  color: #4C4C3F;
  font-size: small;
  line-height: 15px;
}

.customBorder{
  background-color: #808080;
  left: -2px;
  top: -2px;
  padding-top: 10px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 13px;
  width: 105.5%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customBorder p{
  font-size: 11px;
  color: white;
  text-align: center;
}
