.header {
  position: fixed;
  z-index: 1;
  width: 100vw;
  background-color: white;
}

.header .logo {
  width: 150px;
  padding: 10px
}

.button {
  width: 100px;
  color: #fff;
  background-color: #B3D918
}

.content {
  margin: 4.5rem 0.5rem 0 0.5rem;
  min-height: calc(100vh - 80px);
  background-color: white;
}

.leaflet-container {
  width: 100%;
  height: 90vh;
}
