.cardContainer{
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}

.nameContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.compoundFilter {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.text {
  margin: 0;
}

.goToDetailsButton {
  margin-right: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
}
