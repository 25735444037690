.container{
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.mainRow {
  width: 100%
}

.closeButtonRow {
  display: flex;
  justify-content: flex-end;
  width: 100%
}

.additionalInfoContainer {
  padding: 0 1.8rem;
}

.searchInput {
  width: 100%;
  margin: 1rem 0;
}

.compoundFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem 0;
}

.inputSearch {
  width: 100%;
}

.select {
  width: 130px;
  background-color: #d9d9d6;
  color: #000;
}

.selectContainer {
  margin-right: 3rem;
}

.filtersseSelect {
  width: 100%;
}

.table {
  margin-top: 1rem;
}

.rowContainer,
.addBulk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rowContainer {
  width: 100%;
}

.addBulk {
  cursor: pointer;
}

.addIcon,
.editIcon {
  stroke-width: 18px;
  font-size: 18px;
  color: var(--green);
}

.editIcon, .editIconDisabled {
  color: var(--pastelGreen);
  cursor: pointer;
}

.addBulkText {
  color: #000;
  margin: 0 5px 0 10px;
}

.text {
  margin: 0;
}

.increaseStockContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orangeCircle {
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  cursor: pointer;
}

.grayCircle {
  width: 20px;
  height: 20px;
  background-color: #BBBBBB;
  border-radius: 50%;
  cursor: pointer;
}

.nameContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.disabledText{
  margin: 0;
}

.trace{
  width: 50px;
  height: 1.5px;
  background-color: gray;
  opacity: 0.4;
}

.disabled, .disabledText, .disabledButton, .editIconDisabled {
  filter: grayscale(0.90);
  opacity: 0.5;
  cursor: default;
}

.goToDetailsButton, .disabledButton {
  margin-right: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
}
