.csvText{
  color: var(--green);
  margin: 0 1rem 0 0.5rem;
}

.spinContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
