.title {
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
  margin: 0;
}

.subtitle {
  font-size: 21px;
  line-height: 21px;
}

.editAvalabilityContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editQuantityContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.numberOfUnits {
  color: var(--pastelGreen);
  width: 70px;
  text-align: center;
  margin: 0;
  font-size: 25px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
.numberOfUnits::-webkit-outer-spin-button,
.numberOfUnits::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberOfUnits[type=number] {
  -moz-appearance: textfield;
}

.numberOfUnits>div:first-child {
  display: none;
}

.icon {
  font-size: 25px;
  color: var(--pastelGreen);
  stroke: var(--pastelGreen);
  padding: 5px;
  cursor: pointer;
}

.yesOrNoSelect{
  width: 80px;
}

.unavailableOptionsContainer{
  padding: 2rem 0 0 0;
}

.modal{
  min-width: 700px;
}

.replacementProductSelect{
  width: 600px;
}

.replacementContainer{
  margin-top: 2rem;
}
