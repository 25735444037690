.rowContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
}

.select{
  width: 200px;
  border-radius: 10px;
}

