/* Dragging style */
.rowDragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 99999999;
}

.rowDragging td {
  padding: 16px;
  visibility: hidden;
}

.rowDragging .dragVisible {
  visibility: visible;
}

.dragHandleIcon {
  cursor: grab;
  color: #999;
}
