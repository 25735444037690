.container{
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}

.goBackContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  padding: 1rem 0;
}

.goBackContainer:hover .goBackIcon {
  color: var(--green);
  stroke: var(--green);
}

.goBackContainer:hover .goBackTitle {
  color: var(--green);
}

.goBackIcon {
  font-size: 1rem;
  color: #2b2b2b;
  stroke-width: 30;
  stroke: #2b2b2b;
  padding-right: 5px;
  transition: all 200ms;
}

.goBackTitle {
  margin: 0;
  font-weight: bold;
  color: #2b2b2b;
  transition: all 200ms;
  font-size: 1rem;
}

.title{
  color: #000;
  font-size: 1.5rem;
  margin: 0;
}

.detailsContainer{
  background-image: url('../../../img/gradient.png');
  background-size: cover;
  padding: 1rem 1.5rem;
  margin: 1rem 0 2rem 0;
  border-radius: 20px;
  -webkit-box-shadow: 1px 1px 7px 5px rgba(0,0,0,0.1);
  box-shadow: 1px 1px 7px 5px rgba(0,0,0,0.1);
}

.rowContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.divider{
  height: 2px;
  background-color: #fff;
  width: 100%;
}

.detailsTitle{
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding: 0 0 1rem 0;
  margin: 0;
}

.detailsText{
  color: #fff;
  font-size: 16px;
  padding: 1rem 0 0 0;
  margin: 0;
}

.management{
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.editContainer, .management{
  padding: 1rem;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 7px 5px rgba(0,0,0,0.1);
  box-shadow: 1px 1px 7px 5px rgba(0,0,0,0.1);
  border-radius: 20px;
  min-height: 60vh;
}

.editContainerTitle{
  font-size: 20px;
  font-weight: bold;
  color: #1CCA45;
}

.editContainerDivider{
  height: 3px;
  width: 100%;
  background-color: #EBEBE9;
  margin: 0.5rem 0;
}

.lockIcon{
  width: 13px;
  height: 16px;
  cursor: pointer;
  padding: 0 1.5rem;
}

.unlockIcon, .unlockDisabled{
  width: 17px;
  height: 16px;
  cursor: pointer;
  padding:  0 1.5rem;
}

.editIcon {
  color: var(--pastelGreen);
  cursor: pointer;
  stroke-width: 18px;
  font-size: 18px;
}

.text {
  margin: 0;
  color: #000;
}

.increaseStockContainer, .disabled {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.orangeCircle {
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  cursor: pointer;
}

.grayCircle {
  width: 20px;
  height: 20px;
  background-color: #BBBBBB;
  border-radius: 50%;
  cursor: pointer;
}

.disabled, .editIconDisabled {
  filter: grayscale(0.90);
  opacity: 0.3;
  cursor: default;
}

.editIconDisabled{
  opacity: 1;
}

.trace{
  width: 50px;
  height: 2px;
  background-color: gray;
  opacity: 0.4;
}

.unlockDisabled{
  cursor: not-allowed;
  filter: grayscale(0.90);
}
