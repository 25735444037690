.text {
  margin: 0;
}

.container {
  padding: 1rem 0;
}

.orderIdContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  align-items: baseline;
  cursor: pointer;
}

.goToDetailsButton {
  margin-right: 10px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}
