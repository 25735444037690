.cardContainer{
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.contentRow {
  justify-content: center;
}

.inputsRow {
  width: 100%;
}

.input {
  width: 100%;
}

.input:hover {
  border-color: var(--green);
}

.addButtonRow {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0.3rem 0 0.3rem;
  padding: 1rem 0.8rem;
}

.addButton {
  background-color: var(--green) !important;
  width: 8rem;
  color: white !important;
  transition: background-color 0.2s;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0.3rem 0 0.3rem;
  padding: 1rem 0.8rem;
}

.button {
  background-color: var(--green) !important;
  width: 6rem;
  color: white !important;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: var(--green);
  color: white;
  border: none;
}

.table tr {
  cursor: pointer;
}

.viewList {
  margin: 0.8rem 0 0.8rem 0;
}

.datePickerRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem 0.8rem;
}

.datePicker {
  width: 85%;
}

.compoundFilter {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
