.monthAndYear{
    font-size: 16px;
    margin-bottom: 5px;
    color: #000;
}

.bigMonthAndYear{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #000;
}

.day{
    font-size: 48px;
    position: relative;
    text-align: left;
    line-height: 40px;
    margin: 0;
    color: #000;
}

.sup{
    position: absolute;
    top: 10px;
    left: 60px;
    font-size: 20px;
}

.sub{
    position: absolute;
    bottom: 10px;
    left: 60px;
    font-size: 20px;
}

.container{
    margin-bottom: 20px;
    padding: 1rem;
}

.rowContainer{
  display: flex;
  flex-direction: row;
  align-items: center;

}

.arrowIcon{
  font-size: 18px;
  color: #6A6A69;
  stroke-width: 30;
  stroke: #6A6A69;
  padding: 5px;
}

.arrowContainer{
  height: 0.8rem;
  width: 0.8rem;
  background-color: #F0F0ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: none;
  margin: 0 0.7rem;
  margin-bottom: 1.6rem;
}
