.title {
  margin: 0 !important;
  margin-left: 0.8rem !important;
}

.arrowIcon{
  color:#64676A;
}

.button{
  background-color: var(--green) !important;
}

.button, .cancelButton{
  margin: 0.8rem 0;
  border: none !important;
  width: 8rem;
  color: #fff !important;
  font-weight: bold;
  transition: background-color 0.2s;
}

.cancelButton{
  background-color: #64676A;
  margin-right: 25px;
}

.button:hover {
  background-color: #b3d918;
  color: #fff;
  border-color: white;
}

.cancelButton:hover{
  background-color: #64676ad5;
  color: #fff;
  border-color: white;
}

.stepsContent{
  min-height: 200px;
  margin-top: 16px;
  padding-top: 20px;
}

.rowContainer{
  display: flex;
  justify-content: space-between;
}

.stepAndButtonsContainer{
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.background{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 98%;
}

.driverMessage{
  color: #64676A;
  margin: 0;
  align-self: end;
}
