.column {
  padding: 0.8rem;
}

.buttonsContainer{
  display: flex;
}

.clearButton, .clearButton:hover{
  background-color: #fff;
  color: var(--green);
  margin-left: 0.8rem;
  border-color: var(--green);
  width: 8rem;
}

.container{
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.noSlotsMessage{
  font-size: 30px;
  color: var(--green);
}

.noSlotsIcon{
  font-size: 60px;
  color: var(--green);
  padding-bottom: 10px;
}
