.trashIcon{
  font-size: 18px;
  color: #ffbf00;
  stroke-width: 20;
  stroke: #ffbf00;
  padding: 5px;
}

.popoverTitle{
  font-size: 21px;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.updateButton{
  height: 100%;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  background-color: transparent;
  padding: 5px;
}

.updateButton{
  border: 2px solid var(--green);
  color: var(--green);
  margin: 0;
}

.buttonsRow{
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}


.session {
	margin: 0;
  margin-bottom: 0.5rem;
  background-color: #F0F0ED !important;
	color: #6A6A69 !important;
  padding: 1rem;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid gray;
}

.trashIconContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 2px solid #ffbf00;
  border-radius: 10px;
  margin-right: 8px;
}

.selectContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selectLabel{
  margin: 10px 0 5px 0;
}
