.loginContainer {
  text-align: center;
  padding: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 15rem;
  height: auto;
  object-fit: contain;
}

.button {
  background-color: #AED31A;
  padding: 10px;
  color: white;
  margin-top: 2rem;
}
