.cardContainer {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.text{
  margin: 0;
}

.compoundFilter {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}

.inputSearch {
  width: 600px;
  flex: 4;
}

.addRow {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}

.addDiscountButton {
  display: flex;
  height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.addDiscountText {
  font-size: 16px;
  color: #000;
}

.iconButton {
  color: #97d700;
  margin-right: 5px;
  font-size: 16px;
}

.retailInputCol {
  margin-right: 10px;
}

.latitudeInput {
  width: 200px;
  margin-right: 5px;
}

.longitudeInput {
  width: 200px;
}

.tabPaneCard {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #95CF3A;
  width: 60px;
  height: 60px;
  border-radius: 30px
}

.dayStyle {
  margin: 0;
  font-weight: bold;
  color: white
}

.table {
  margin-top: 20px;
}

.nameContainer {
  display: flex;
  height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.lockUnlockIcon {
  color: #97d700;
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
}
