.titleContainer{
  display: flex;
  align-items: baseline;
}

.plusIcon{
  color: #97d700;
  margin-right: 5px;
  font-size: 16px;
}

.modalContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.title{
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 1rem;
}

.fieldCard {
  display: flex;
  flex-direction: column;
  margin-right: 5px
}

.smallInput {
  width: 70px;
  margin-right: 5px
}

.largeInput {
  width: 100%;
}

.row{
  display: flex;
  justify-content: space-between;
}
