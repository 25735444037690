.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 0;
}

.realTimesTitle {
  color: var(--green);
  font-weight: bold;
  margin-bottom: 10px;
  width: 15%;
}

.estimatedTimesTitle {
  font-weight: bold;
  margin-bottom: 10px;
  width: 15%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}

.estimatedTimesSlider,
.realTimesSlider {
  width: 80%;
  cursor: default;
}

.realTimesSlider {
  color: var(--green);
}

.labelDate {
  margin-bottom: 0px;
}

.normal {
  color: #000;
  margin-bottom: 0px;
}

.late {
  color: red;
  margin-bottom: 0px;
}

@keyframes appearColor {
  from {
    background-color: #fff;
  }
}

@media screen and (max-width:750px) {
  .row {
    flex-direction: column;
    gap: 20px;
  }
}
