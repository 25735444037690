.container {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.select {
  width: 180px;
}

.inputSearch {
  width: 100%;
}

.compoundFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem 0;
}

.editWindowButton {
  background-color: var(--green) !important;
  width: 8rem;
  color: white !important;
  transition: background-color 0.2s;
}

.container {
  margin-bottom: 20px;
  padding: 1rem;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

}
