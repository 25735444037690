.title{
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
  margin: 0;
}

.subtitle{
  font-size: 21px;
  line-height: 21px;
}

.editButtonsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
}

.numberOfUnits{
  color: var(--pastelGreen);
  width: 70px;
  text-align: center;
  margin: 0;
  font-size: 25px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
.numberOfUnits::-webkit-outer-spin-button,
.numberOfUnits::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberOfUnits[type=number] {
  -moz-appearance: textfield;
}

.icon{
  font-size: 25px;
  color: var(--pastelGreen);
  stroke: var(--pastelGreen);
  padding: 5px;
  cursor: pointer;
}

.warning{
  color: #ff4949
}
