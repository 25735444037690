.container{
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}

.table{
  margin-top: 50px;
}

.compoundFilter {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
}

.noMarginText {
  margin: 0;
}

.rangePicker {
  width: 600px;
  border: none;
  flex: 2;
}

.inputSearch {
  width: 600px;
  flex: 4;
}

.addRow {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
  cursor: pointer;
}

.addNotificationText {
  font-size: 16px;
  color: var(--green);
}

.iconButton {
  color: #97d700;
  margin-right: 5px;
  font-size: 16px;
}
