.layoutContainer {
  height: 100vh;
  margin-top: 5px;
}

.siderContainer {
  height: 100%;
  padding: 10px;
  border-radius: 15px;
  margin: 5px 0px 5px 10px;
}

.zonesFilterList {
  height: 100px;
  overflow: auto;
}

.zoneListItem {
  padding: 0px;
}

.zonesFilterList::-webkit-scrollbar,
.windowsFilterList::-webkit-scrollbar,
.eventsCard::-webkit-scrollbar,
.collapseList::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

.zonesFilterList::-webkit-scrollbar-track,
.windowsFilterList::-webkit-scrollbar-track,
.eventsCard::-webkit-scrollbar-track,
.collapseList::-webkit-scrollbar-track {
  background: #F0F2F5;
  /* color of the tracking area */
}

.zonesFilterList::-webkit-scrollbar-thumb,
.windowsFilterList::-webkit-scrollbar-thumb,
.eventsCard::-webkit-scrollbar-thumb,
.collapseList::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #F0F2F5;
  /* creates padding around scroll thumb */
}

.datePicker {
  width: 100%;
}

.deliveryWindowsFilterList {
  height: 200px;
  width: 180px;
  overflow: auto;
}

.deliveryWindowsListItem {
  padding: 0px;
}

.contentContainer {
  margin: 4px 16px;
  padding: 2px;
  min-height: 280px;
}

.searchInput {
  width: 100%;
  margin-bottom: 8px;
  border-radius: 15px;
}

.contentRow {
  height: 90%;
}

.mapContainer {
  border-radius: 15px;
  padding: 10px;
  background-color: white;
  height: 94vh;
}

.driverInfoContainer {
  padding: 10px 0px 0px 5px;
}

.infoRouteDivider {
  margin: 5px 0px;
}

.routesInfoContainer {
  padding-left: 5px;
  margin-bottom: 5px;
}

.loadingMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.map {
  margin: 10px 0px;
}

.eventsSider {
  height: 94vh;
}

.eventsCard {
  border-radius: 20px;
  height: 100%;
  overflow: auto;
}

.loadingEventsContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
