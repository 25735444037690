.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loadingContainer {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hours {
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ADDFB3 !important;
  color: #fff;
  font-weight: bold;
  padding: 1.3rem !important;
  transform: translateX(50%);
}

.kiwiShift {
  color: var(--green);
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 1px;
}

.schedule {
  overflow: auto;
  height: 80vh;
  display: grid;
  grid-template-rows: [times] auto;
  grid-template-columns:
    [drivers] 180px [time-0500] 1fr [time-0530] 1fr [time-0600] 1fr [time-0630] 1fr [time-0700] 1fr [time-0730] 1fr [time-0800] 1fr [time-0830] 1fr [time-0900] 1fr [time-0930] 1fr [time-1000] 1fr [time-1030] 1fr [time-1100] 1fr [time-1130] 1fr [time-1200] 1fr [time-1230] 1fr [time-1300] 1fr [time-1330] 1fr [time-1400] 1fr [time-1430] 1fr [time-1500] 1fr [time-1530] 1fr [time-1600] 1fr [time-1630] 1fr [time-1700] 1fr [time-1730] 1fr [time-1800] 1fr [time-1830] 1fr [time-1900] 1fr [time-1930] 1fr [time-2000] 1fr [time-2030] 1fr [time-2100] 1fr [time-2130] 1fr [time-2200] 1fr [time-2230] 1fr [time-2300] 1fr [time-2330] 1fr [arrowRight] 1fr;
}

/* selects all the child elements div inside a .schedule */
.schedule>div {
  background-color: white;
  border-left: 1px solid #d9d9d6;
  border-bottom: 1px solid #d9d9d6;
  padding: 8px;
}

.schedule>div:nth-child(-n+42) {
  background-color: white;
  border: none;
}

.gridColumn {
  display: grid;
}

.trackSlot {
  display: none;
  /* hidden on small screens and browsers without grid support */
  text-align: center;
}

@supports(display:grid) {
  @media screen and (min-width:700px) {
    .track-slot {
      display: block;
      padding: 10px 5px 5px;
      position: sticky;
      top: 0;
      background-color: rgba(255, 255, 255, .9);
    }
  }
}

.driver {
  grid-column: times;
  font-size: 16px;
  line-height: 17px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}

.driverSlot {
  border-left: none !important;
}

.shiftDurationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shiftDuration {
  color: #ADDFB3;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-left: 5px;
}

.shiftDurationIcon {
  font-size: 14px;
  color: #ADDFB3;
  stroke-width: 30;
  stroke: #ADDFB3;
}

.arrowIcon {
  font-size: 18px;
  color: #6A6A69;
  stroke-width: 30;
  stroke: #6A6A69;
  padding: 5px;
}

.arrowContainer {
  height: 1rem;
  width: 1rem;
  background-color: #F0F0ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1.3rem;
  border: none;
  margin: 0 8px;
}
