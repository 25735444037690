.cardContainer {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.mainRow {
  width: 100%
}

.inputSearch {
  width: 600px;
  flex: 4;
}

.table {
  height: 100%;
  margin-top: 10px;
}

.table tr {
  cursor: default;
}

.compoundFilter {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.01em solid var(--gray);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.rangePicker {
  width: 600px;
  border: none;
  flex: 2;
}

.orderIdContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.goToDetailsButton {
  margin-right: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.text {
  margin: 0;
}

.editDriverContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.editIcon {
  cursor: pointer;
}

.productSortContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
}

.productSortTitle {
  margin: 0;
  padding-right: 1rem;
}
