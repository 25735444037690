.title {
  margin: 0 !important;
  margin-left: 0.8rem !important;
}

.driverShiftsMessage{
  color: #000;
  margin-left: 0.8rem;
  font-size: 1rem;
}

.arrowIcon{
  color: var(--gray);
}


.button, .cancelButton{
  margin: 0.8rem 0;
  width: 8rem;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.2s;
}

.cancelButton{
  background-color: var(--gray);
  margin-right: 25px;
}

.button:hover {
  color: #fff;
  border-color: white;
}

.cancelButton:hover{
  background-color: #64676ad5;
  color: #fff;
  border-color: white;
}

.stepsContent{
  min-height: 200px;
  margin-top: 16px;
  padding-top: 20px;
}

.rowContainer, .stepAndButtonsContainer{
  display: flex;
  justify-content: space-between;
}

.routeMessage{
  color: var(--gray);
}

.currentDriverTable{
  margin-bottom: 30px;
}

.stepAndButtonsContainer{
  padding: 15px 0;
}

.background{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.spin{
  margin: 0 25px;
}

.optionalStepTitle{
  margin: 0 !important;
  margin-left: 0.8rem !important;
  padding-bottom: 15px;
}
