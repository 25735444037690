.container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  height: 100%;
}

.title{
  font-weight: bold;
}

.text{
  color: #000;
}

.productImage{
  object-fit: contain;
  height: 200px;
  width:  200px;
}

.imageAndDetails{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lockIcon{
  width: 13px;
  height: 16px;
  cursor: pointer;
}

.unlockIcon{
  width: 17px;
  height: 16px;
  cursor: pointer;
}

.fieldTitle{
  font-weight: 600;
  margin: 0;
}

.formItems{
  width: 100%;
  margin-left: 4rem;
}

.form{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lockButtonAndSaveChanges{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.buttonSaveChanges{
  background-color: var(--green);
  color: #fff;
  border-color: var(--green);
  margin-bottom: 24px;
}

.buttonSaveChanges:hover{
  color:#fff;
  border-color: var(--green);
  background-color: var(--green);
}

.taxesContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.imageLinks{
  width: 100%;
  flex: 3;
  flex-wrap: wrap;
}

.imageLink{
  flex: 1
}
