.gridCell, .gridCellOdd{
  cursor: pointer;
}

.gridCell:nth-child(2n + 3){
  border-left: 1px solid rgb(248, 248, 248);
}

.gridCellOdd:nth-child(2n + 2){
  border-left: 1px solid rgb(248, 248, 248);
}

.cellWithBorders{
  border-left: 1px solid #e0e0e0;
}

.cellWithoutBorders{
  background-color: #fff;
  border-left: 1px solid rgb(248, 248, 248);
}

.popoverTitle{
  font-size: 21px;
}

.createButton{
  height: 100%;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  background-color: transparent;
  padding: 5px;
  color: var(--green);
  border: 2px solid var(--green);
}

.buttonRow{
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selectLabel{
  margin: 10px 0 5px 0;
}
