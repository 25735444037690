.text{
  margin: 0;
}

.container {
  padding: 1rem;
  height: 100vh;
  overflow: auto;
}

.tabsContainer {
  padding: 1rem;
  padding-left: 0;
  overflow: auto;
}

.goBackContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  margin-bottom: 1rem;
}

.goBackContainer:hover .goBackIcon {
  color: var(--green);
  stroke: var(--green);
}

.goBackContainer:hover .goBackTitle {
  color: var(--green);
}

.goBackIcon {
  font-size: 18px;
  color: #2b2b2b;
  stroke-width: 30;
  stroke: #2b2b2b;
  padding: 5px;
  transition: all 200ms;
}

.goBackTitle {
  margin: 0;
  font-weight: bold;
  color: #2b2b2b;
  transition: all 200ms;
}

.infoCircle {
  margin-right: 10px;
  height: 22px;
  width: 22px;
}

.nameContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
