.container{
  padding: 1rem;
  height: 100vh;
  overflow: auto;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.dateCard {
  margin-bottom: 20px;
  padding: 1rem;
}

.monthYearText {
  font-family: Rubik;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000;
}

.dayAndWeekContainer {
  display: flex;
  flex-direction: row;
  margin-top: -40px
}

.weekCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -20px
}

.dayText {
  color: #000;
  width: 71px;
  height: 85px;
  margin: 0 14px 0 0;
  font-family: Rubik;
  font-size: 72px;
  font-weight: 300;
  letter-spacing: -0.58px;
}

.th {
  color: #000;
  margin: 12px 68px 3px 14px;
  font-family: Rubik;
  font-size: 25px;
  letter-spacing: -0.2px;
  margin-bottom: -10px;
}

.weekText {
  color: #000;
  margin: 3px 0 12px 14px;
  font-family: Rubik;
  font-size: 25px;
  letter-spacing: -0.2px;
}

.arrowIcon {
  color: #000;
  font-size: 22px;
}

.selectWarehouseContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 20px;
}

.selectWarehouseCard {
  display: flex;
  flex-direction: column
}

.selectWarehouseTitle {
  color: #000;
  width: 215px;
  height: 28px;
  font-family: Rubik;
  font-size: 23px
}

.selectWarehouse {
  width: 200px;
  margin-top: -15px
}

.editWindowContainer {
  display: flex;
  align-items: baseline;
  width: 300px;
  height: 28px;
  cursor: pointer;
}

.editWindowTitle {
  color: #000;
  margin: 0 5px 0 0;
  font-family: Rubik;
  font-size: 23px;
  font-weight: 500;
}

.editWindowIconCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin: 9px 0 5px 5px;
  padding: 3px 3px 3px 3px;
  border: solid 1px #addfb3;
  background-color: #addfb3;
}

.editWindowIcon {
  color: white;
}

.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px
}

.labelsRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 22px;
}

.filledCapacityCard {
  display: flex;
  height: 32px;
  width: 32px;
  background-color: #9CCFAB;
}

.totalCapacityCard {
  display: flex;
  height: 32px;
  width: 32px;
  background-color: #D5D5D6;
}

.capacityText {
  width: 154px;
  height: 28px;
  margin: 2px 0 2px 11px
}
