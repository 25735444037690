.title {
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0;
  color: #2b2b2b;
}

.button {
  background-color: var(--green);
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  margin-bottom: 1rem;
}

.select {
  width: 200px;
}

.message {
  font-weight: bold;
  font-size: 18px;
  margin: 1rem 0;
}

.text{
  margin: 0;
}

.container{
  padding: 1rem 0;
}
