.editIcon {
  color: #addfb3;
  font-size: 14px;
  cursor: pointer;
}

.deleteIcon {
  color: #dd2c14;
  font-size: 14px;
  cursor: pointer;
}

.grayDeleteIcon {
  color: #D6D6D6;
  font-size: 14px;
  cursor: not-allowed;
}

.plusIcon {
  height: 22px;
  width: 22px;
}

.addNewWindowContainer {
  display: flex;
  align-Items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 15px;
}

.addNewWindowCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addNewWindowText {
  margin: 0px 5px;
  font-family: Rubik;
  font-size: 18px;
  letter-spacing: -0.2px;
}

.predeterminedText {
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: -0.17px;
}
