.collapseList {
  padding: 0px;
  margin: 0px;
  height: 70px;
  overflow: auto;
}

.collapseListItem {
  font-size: 10px;
}
