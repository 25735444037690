.table {
  margin: 1rem calc(1rem + 20px);
}

.editIcon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.text {
  margin: 0;
}

.textGray {
  margin: 0;
  color: #d9d9d9;
}

.editProductQuantityContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

.popoverContentContainer {
  display: flex;
  flex-direction: column;
}
