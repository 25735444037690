
.rowContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.filtersContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.clearIcon{
  color: #6A6A69;
  cursor: pointer;
  padding: 10px;
  -webkit-animation: appear 0.5s;
  transition: appear 0.5s;
}


@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.001);
  }
}
