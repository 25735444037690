.mainContainer {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.gridContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}
