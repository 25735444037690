.cardGrid {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 2px solid var(--green);
  border-radius: 8px;
  text-align: center;
  background-color: white;
  color: #111;
  font-weight: bold;
  font-size: 10px;
  position: relative;
  overflow: hidden;
}

.tabPane {
  height: 150px;
  overflow-y: auto;
}

.todayDateContainer {
  background-color: #808080;
  margin: 0px;
  width: 80px;
}

.todayDate {
  font-size: 14px;
  margin: 0px;
  color: white
}

.monthDay {
  font-size: 16px;
}

.week {
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
}
