.title{
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  margin: 0;
}

.subtitle{
  font-size: 16px;
  line-height: 21px;
}
