.title {
  margin: 0 !important;
  margin-left: 0.8rem !important;
}

.inputSearch {
  margin: 10px 0 10px 0;
}

.inputRow{
  display: flex;
  flex-direction: row;
}

.unassignedLegsContainer{
  padding-top: 0.8rem;
}
