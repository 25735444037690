.cardContainer {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
  background-color: #fff;
}

.stepsContainer{
  margin-top: 2px;
  border: 1px solid #bcbcbc;
  border-radius: 20px 0 0 20px;
  min-height: calc(100% - 2px);
}

.container, .stepsContainer{
  padding: 30px;
}

.title {
  margin: 0 !important;
  margin-left: 0.8rem !important;
  padding-bottom: 10px
}

.listItem {
  color: #111111;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}

